import React, { Component } from 'react';
import Prismic from 'prismic-javascript';
import FontAwesome from 'react-fontawesome'
import './styles.scss'

import PageTitle from '../../components/page-title/';

class AboutLayout extends Component {
  state = {
    doc: null,
  }

  componentWillMount() {
    const apiEndpoint = 'https://alllcare.prismic.io/api/v2';

    Prismic.api(apiEndpoint).then(api => {
      api.query(
        Prismic.Predicates.at('document.type', 'about_us')
      ).then(response => {
        if (response) {
          this.setState({ doc: response.results[0] });
        }
      });
    });
  }

  render() {
    if (this.state.doc) {
      const document = this.state.doc.data;
      return (
        <div>
          <PageTitle
            title={document.title[0].text}
          />
          <div className='aboutContentContainer'>
            <div className='aboutDescription'>
              {document.description.map((desc , i) => {
                return <p key={i}>{desc.text}</p>
              })}
            </div>
            <div className='aboutImageContainer'>
              <div className='aboutImages'>
                {document.image_grid.map((grid, i) => {
                  return <div key={i} className='aboutImg' style={{ backgroundImage: `url(${grid.image.url})`}}></div>
                })}
              </div>
            </div>
            <div className='aboutStatmentSection'>
              {document.statments.map((statment, i) => {
                console.log(window.screen.width)
                return (
                  <div className='aboutStatmentContainer' key={i}>
                    <div className='iconContainer'>
                      <div className='icon'>
                        {window.screen.width > 768 ?
                        <FontAwesome className='aboutIcon' name={statment.icon} size='3x'/>
                        :
                        <FontAwesome className='aboutIcon' name={statment.icon} size='2x'/>
                        }
                      </div>
                    </div>
                    <div className='aboutStatment'>
                      <h2>{statment.title[0].text}</h2>
                      {statment.description.map((desc, i) => {
                        return <p key={i}>{desc.text}</p>
                      })}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      )
    }
    return (
      <div className="spinner">
        <div className="bounce1"></div>
        <div className="bounce2"></div>
        <div className="bounce3"></div>
      </div>
    )
  }
}

export default AboutLayout;
